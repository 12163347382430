<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- name -->
      <template #[`item.name`]="{ item }">
        {{ item.name.en }}
      </template>

      <!-- currency -->
      <template #[`item.currency`]="{ item }">
        {{ item.currency }}
      </template>

      <!-- active -->
      <template #[`item.active`]="{ item }">
        <v-chip :color="item.fiat.active ? 'success' : ''">
          {{ item.fiat.active ? 'ACTIVE' : 'INACTIVE' }}
        </v-chip>
        <v-chip
          v-if="item.fiat.base"
          color="info"
        >
          BASE
        </v-chip>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="resource = item"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="resource">
                <edit-dialog
                  v-if="$can('country:update', 'country_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>

              <!-- Required to hold some space -->
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import EditDialog from './EditDialog.vue'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loading, options, paginate, items, itemsTotal, headerprops } = props.tableList

    const resource = ref(0)

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'NAME', value: 'name' },
      { text: 'CURRENCY', value: 'currency' },
      { text: 'ACTIVE', value: 'active', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ]

    return {
      resource,

      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
